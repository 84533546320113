.container-crop {
	height: 500px;
	/* width: 100vw; */
}

.container-cropper {
	height: 90%;
	padding: 10px;
}

.cropper {
	height: 90%;
	position: relative;
}

.slider {
	height: 10%;
	display: flex;
	align-items: center;
	margin: auto;
	width: 60%;
}
